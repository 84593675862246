import request from "@/utils/request";
import Export from '@/utils/export';
// 卡券新增
export function getCardEdit(data) {
  return request("post", "/apm/sp/cardCoupon/create", data);
}

// 卡券列表
export function getTableList(data) {
  return request("post", "/apm/sp/cardCoupon/cardList", data);
}

// 查询优惠活动
export function getActivity(data) {
  return request("post", "/apm/preferential/activity/find", data);
}

// 编辑优惠活动
export function goUpdateActivity(data) {
  return request("post", "/apm/preferential/activity/mod", data);
}

// 卡券订单
export function getOrderList(data) {
  return request("post", "/apm/card/order/page", data);
}

// 卡券订单导出
export function getCardOrderExport(data) {
  return Export('post', '/apm/card/order/export', data, "平台卡券订单")
}


// 平台卡券适用范围
export function getCardServiceList(data) {
  return request('post', '/apm/room//serviceListByCard', data)
}

// 卡券删除
export function goDeleteCard(data) {
  return request('post', '/apm/cardCoupon/del', data)
}


// 卡券编辑时查详情
export function getCardDetail(data) {
  return request('post', '/apm/cardCoupon/findById', data)
}

// 卡券编辑
export function goUpdateCard(data) {
  return request('post', '/apm/cardCoupon/update', data)
}
// 用户列表搜索框-平台商户端
export function platformUser(data) {
  return request('post', '/apm/ums/shop/user/platform/list', data)
}
// 平台批量发放卡券
export function batchSend(data) {
  return request('post', '/apm/ums/shop/user/batch/give/card', data)
}
// 发放记录
export function sendRecord(data) {
  return request('post', '/apm/ums/shop/user/cardGiveRecord', data)
}
// 发放记录明细
export function sendRecordDetail(data) {
  return request('get', '/apm/ums/shop/user/cardGiveRecordDetail', data)
}